import { DateTime } from "luxon";
import upperFirst from "lodash/upperFirst";

import store from "@/store";

export default {
    install(Vue) {
        Vue.filter("upperFirst", function (value) {
            return upperFirst(value);
        });

        Vue.filter("phoneNumber", function (value) {
            // TODO: fix & use
            return value.replace(/[^0-9]/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        });

        Vue.filter("firstChars", function (value, number) {
            if (value && number) {
                return value.slice(0, number);
            }
            return;
        });

        Vue.filter("toCurrency", function (value) {
            if (typeof value !== "number") {
                return value;
            }
            const formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
            });
            return formatter.format(value);
        });

        Vue.filter("mimeTypeList", function (value) {
            const index = value.indexOf("/");
            if (index !== -1) {
                return value.substring(value.indexOf("/") + 1);
            }

            return;
        });

        Vue.filter("datetimeFormat", function (value, fmt) {
            const { timeFormat24Hour, location } = store.state.settings;
            if (!value) return null;
            const date = DateTime.fromISO(value, { zone: location });
            if (!date.isValid) {
                throw `Invalid datetime value: ${value}.`;
            }
            switch (fmt) {
                case "dateOnlyNoZone": {
                    const val = value.split("T");
                    return DateTime.fromISO(val[0]).toLocaleString(DateTime.DATE_SHORT);
                }
                case "dateOnly": {
                    return date.toLocaleString(DateTime.DATE_SHORT);
                }
                case "dateShort": {
                    return date.toLocaleString(DateTime.DATE_MED);
                }
                case "dateShortWeekDay": {
                    return date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
                }
                case "timeOnly": {
                    const dateTime = timeFormat24Hour
                        ? date.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET)
                        : date.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
                    return `${dateTime}`;
                }
                case "timeNoOffset": {
                    const dateTime = timeFormat24Hour
                        ? date.toLocaleString(DateTime.TIME_24_WITH_SECONDS)
                        : date.toLocaleString(DateTime.TIME_WITH_SECONDS);
                    return `${dateTime}`;
                }
                case "timeSimple": {
                    const dateTime = timeFormat24Hour
                        ? date.toLocaleString(DateTime.TIME_24_SIMPLE)
                        : date.toLocaleString(DateTime.TIME_SIMPLE);
                    return `${dateTime}`;
                }
                default:
                    throw `Unsupported datetime format: ${fmt}.`;
            }
        });

        Vue.filter("truncate", (text, maxChars) => {
            if (!text) return;
            return text.length < maxChars ? text : `${text.slice(0, maxChars)}...`;
        });
    },
};
