import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "vuetify/lib/locale/en";
import pt from "vuetify/lib/locale/pt";
import es from "vuetify/lib/locale/es";

Vue.use(VueI18n);

const messages = {
    en: {
        ...require("@/locales/en.json"),
        $vuetify: en,
    },
    pt: {
        ...require("@/locales/pt.json"),
        $vuetify: pt,
    },
    es: {
        ...require("@/locales/es.json"),
        $vuetify: es,
    },
};

// if (module.hot) {
//   module.hot.accept(['vuetify/lib/locale/en', 'vuetify/lib/locale/pt', 'vuetify/lib/locale/es'], function () {
//     i18n.setLocaleMessage('en', require('vuetify/lib/locale/en').default)
//     i18n.setLocaleMessage('pt', require('vuetify/lib/locale/pt').default)
//     i18n.setLocaleMessage('es', require('vuetify/lib/locale/es').default)
//   })
// }

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "en",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages,
    silentTranslationWarn: true,
});
