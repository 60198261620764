// Vue 3 Refactor Note: Use defineAsyncComponent from Vue instead of this ES6 Reducer
export function ComponentLoader(components, additionalComponents = {}) {
    if (!Array.isArray(components)) {
        components = ComponentObjectToArray(components);
    }

    if (components.length === 0) {
        return {};
    }

    return Object.assign(
        components.reduce((obj, item) => {
            return Object.assign(obj, {
                // WebPack requires that all dynamic imports have some portion of their import path hard coded for webpack to use as a split-point for chunking
                // DO NOT REMOVE THE MAGIC COMMENT - Without it webpack tries to bundle the js/css/html sub-files of each component as top level chunks.
                [item.name]: () =>
                    import(
                        /* webpackInclude: /\.vue$/ */
                        "@cmp/" + item.path
                    ),
            });
        }, {}),
        additionalComponents,
    );
}

export function ComponentObjectToArray(components) {
    if (Array.isArray(components)) {
        return components;
    }

    return Object.keys(components).map((key) => {
        return {
            name: key,
            path: components[key],
        };
    });
}

export function TabComponentLoader(tabs, components = null, additionalComponents = {}) {
    if (!Array.isArray(tabs)) {
        throw "TabComponentLoader can only load tab arrays!";
    }

    if (components === null) {
        components = [];
    } else if (!Array.isArray(components)) {
        components = ComponentObjectToArray(components);
    }

    tabs.forEach((x) => {
        if (!x.component || !x.componentPath) {
            return;
        }

        components.push({
            name: x.component,
            path: x.componentPath,
        });
    });

    return ComponentLoader(components, additionalComponents);
}
