import { InternalService } from "@services";
import router from "@/router";

const state = {
    subscription: {},
    seatsUsed: -1,
};

const getters = {
    noDefaultPaymentMethod: (state) => state.subscription.noDefaultPaymentMethod,

    paymentActionRequired: (state) => state.subscription.paymentActionRequired,

    corruptSubscription: (state) => state.subscription.corruptSubscription,

    needsUpdateSubscription: (state) => state.subscription.needsUpdateSubscription,

    expired: (state) => state.subscription.expired,

    internal: (state) => state.subscription.internal,

    external: (state) => state.subscription.external,

    seats: (state) => state.subscription.seats,

    seatsUsed: (state) => state.seatsUsed,
};

const mutations = {
    setSeatsUsed(state, seatsUsed) {
        state.seatsUsed = seatsUsed;
    },

    incSeatsUsed(state, v) {
        if (!this.internal) {
            state.seatsUsed += v;
        }
    },

    decSeatsUsed(state, v) {
        if (!this.internal) {
            state.seatsUsed -= v;
        }
    },
};

const actions = {
    checkHeartbeat({ state }) {
        InternalService.adminHeartbeat().then((r) => {
            state.subscription = r.data.subscription;
            if (state.subscription.expired) {
                router.push("/billing", { replace: true });
            }
            if (state.subscription.internal && router.currentRoute.path === "/billing") {
                // this is not the best way to handle this, but the router guards are exected before
                // checking the heartbeat
                router.push("/", { replace: true });
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
