import { post, setAuthHeader, removeAuthHeader, TokenService, UserService } from "@services";
import router from "@/router";
import jwt_decode from "jwt-decode";
import { tracer } from "@/diagnostics/tracer";

const state = {
    user: null,
    userCreds: null,
};

const getters = {
    user: (state) => {
        return { ...state.user };
    },
    userCreds: (state) => {
        return { ...state.userCreds };
    },
};

const actions = {
    async setUser({ commit }) {
        const r = await UserService.getCurrentUser();
        const user = r.data;
        commit("user", user);
    },

    setUserCreds({ commit }) {
        const accessToken = TokenService.getToken();
        const creds = jwt_decode(accessToken);
        const { isOperator, isDeveloper } = creds;
        const userCreds = { isOperator, isDeveloper };
        commit("userCred", userCreds);
    },

    async login({ dispatch }, { email, password }) {
        tracer.info("[AUTH] Login Attempt | " + email);
        const params = {
            email,
            password,
            adminPortal: true,
        };
        const r = await post("/public/auth/authenticate", params);

        const accessToken = r.data.accessToken.token ? r.data.accessToken.token : r.data.accessToken;
        TokenService.saveToken(accessToken);

        const refreshToken = r.data.refreshToken.token ? r.data.refreshToken.token : r.data.refreshToken;
        TokenService.saveRefreshToken(refreshToken);

        setAuthHeader();
        dispatch("setUser");
        dispatch("setUserCreds");
        dispatch("heartbeat/checkHeartbeat", null, { root: true });
        router.push(router.history.current.query.redirect || "/");
    },

    logout({ commit }) {
        tracer.info("[AUTH] Logout");
        TokenService.removeToken();
        TokenService.removeRefreshToken();
        removeAuthHeader();
        commit("user", null);
        commit("userCred", null);
        window.location.href = "/login";
    },
};

const mutations = {
    user(state, user) {
        state.user = user;
    },
    userCred(state, userCreds) {
        state.userCreds = userCreds;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
