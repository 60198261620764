const state = {
    apiURL: "",
    apiKey: "",
    userAppUrl: "",
    googleMapsApiKey: "",
};

const mutations = {
    apiURL(state, apiURL) {
        state.apiURL = apiURL;
    },

    apiKey(state, apiKey) {
        state.apiKey = apiKey;
    },

    userAppUrl(state, userAppUrl) {
        state.userAppUrl = userAppUrl;
    },

    googleMapsApiKey(state, googleMapsApiKey) {
        state.googleMapsApiKey = googleMapsApiKey;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
