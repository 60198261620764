import { get, post, del } from "./api.service";

const AssetService = {
    deleteAsset(assetId) {
        return del("/admin/asset", { assetId });
    },
    getAssetMetaData(assetId) {
        return get("/admin/asset", { assetId });
    },
    downloadAsset(assetId, silent) {
        return this.downloadAssetUrl(assetId, silent);
    },
    downloadAssetUrl(assetId) {
        return post("/admin/asset/download", { assetId }, true);
    },
    searchAssets(params) {
        return get("/admin/asset/search", params);
    },
    uploadStart(params) {
        return post("/admin/asset/upload/begin", params);
    },
    uploadComplete(params) {
        return post("/admin/asset/upload/complete", params);
    },
};

export { AssetService };
