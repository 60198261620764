import { get, post, del } from "./api.service";

const RegisterService = {
    invite(params) {
        return post("/admin/invitation", params);
    },

    getSeatCount() {
        return get("/public/registration/seats");
    },

    registerInviteSearchGet(params) {
        return get("/admin/invitation/search", params);
    },

    registerInviteDeletePost(params) {
        return del("/admin/invitation", params);
    },
};

export { RegisterService };
