import { get, post, patch, del } from "./api.service";

const TrainingService = {
    searchTrainingCourses(params) {
        return get("/admin/training/course/search", params);
    },
    getTrainingCourse(courseId) {
        return get("/admin/training/course", { courseId });
    },
    createTrainingCourse(params) {
        return post("/admin/training/course", params);
    },
    updateTrainingCourse(params) {
        return patch("/admin/training/course", params);
    },
    deleteTrainingCourse(courseId) {
        return del("/admin/training/course", { courseId });
    },
    searchTrainingAssets(params) {
        return get("/admin/training/course/asset/search", params);
    },
    getTrainingAsset(params) {
        return get("/admin/training/course/asset", params);
    },
    linkCourseAsset(params) {
        return post("/admin/training/course/asset", params);
    },
    unLinkCourseAsset(params) {
        return del("/admin/training/course/asset", params);
    },
    // get credits within a course
    getCourseCredit(params) {
        return get("/admin/training/course/credit", params);
    },
    // get a single credit topic
    getCourseCreditTopic(creditTopicId) {
        return get("/admin/training/credit", { creditTopicId });
    },
    // update a single credit topic
    updateCourseCreditTopic(params) {
        return patch("/admin/training/credit", params);
    },
    createCourseCreditTopic(params) {
        return post("/admin/training/credit", params);
    },
    // creates a credit link between course and credits
    createCourseCreditLink(params) {
        return post("/admin/training/course/credit", params);
    },
    // Search all course topics
    searchCreditTopics(params) {
        return get("/admin/training/credit/search", params);
    },
    // delete a course credit
    deleteCourseCredit(creditTopicId) {
        return del("/admin/training/credit", { creditTopicId });
    },
    // delete a course credit link
    deleteCourseCreditLink(courseCreditId) {
        return del("/admin/training/course/credit", { courseCreditId });
    },
    linkCourseHeader(params) {
        return post("/admin/training/course/header/link", params);
    },
    unlinkCourseHeader(params) {
        return post("/admin/training/course/header/unlink", params);
    },
    // Delete instructor
    deleteCourseInstructor(instructorId) {
        return del("/admin/training/instructor", { instructorId });
    },
    // get a single instructor
    getInstructor(instructorId) {
        return get("/admin/training/instructor", { instructorId });
    },
    // Create single instructor
    createInstructor(params) {
        return post("/admin/training/instructor", params);
    },
    // update a single instructor
    updateInstructor(params) {
        return patch("/admin/training/instructor", params);
    },

    // Delete instructor link
    deleteCourseInstructorLink(courseInstructorId) {
        return del("/admin/training/course/instructor", { courseInstructorId });
    },
    // Get instructors in a course
    getCourseInstructor(params) {
        return get("/admin/training/course/instructor", params);
    },
    // Create instructor link
    createCourseInstructorLink(params) {
        return post("/admin/training/course/instructor", params);
    },
    searchInstructors(params) {
        return get("/admin/training/instructor/search", params);
    },
    // Create instructor profile image link
    linkInstructorProfile(params) {
        return post("/admin/training/instructor/profileimage/link", params);
    },
    // Unlink instructor profile image link
    unlinkInstructorProfile(params) {
        return post("/admin/training/instructor/profileimage/unlink", params);
    },
    // search enrollments
    searchEnrollments(params) {
        return get("/admin/training/enrollment/search", params);
    },

    getEnrollment(params) {
        return get("/admin/training/enrollment", params);
    },
    startEnrollment(params) {
        return post("/admin/training/enrollment/start", params);
    },

    cancelEnrollment(courseEnrollmentId) {
        return post("/admin/training/enrollment/cancel", { courseEnrollmentId });
    },

    // --------- Webinar Session Endpoints -----------//
    searchWebinars(params) {
        return get("/admin/training/course/webinar/search", params);
    },
    getWebinar(CourseWebinarId) {
        return get("/admin/training/course/webinar", { CourseWebinarId });
    },
    createWebinar(params) {
        return post("/admin/training/course/webinar", params);
    },
    // Fails if attendees present
    editWebinar(params) {
        return patch("/admin/training/course/webinar", params);
    },
    // Fails if attendees present
    deleteWebinar(params) {
        return del("/admin/training/course/webinar", params);
    },
    // Fails if attendees present
    cancelWebinar(params) {
        return post("/admin/training/course/webinar/cancel", params);
    },

    createWebinarKey(courseWebinarId) {
        return post("/admin/training/course/webinar/instructor/regenerate", { courseWebinarId });
    },

    searchWebinarReg(params) {
        return get("/admin/training/enrollment/webinar/registration/search", params);
    },
    getWebinarReg(webinarRegistrationId) {
        return get("/admin/training/course/webinar/registration", { webinarRegistrationId });
    },
    markAttendance(params) {
        return post("/admin/training/enrollment/webinar/registration/attendance", params);
    },
    // Fails if attendees present
    deleteWebinarReg(webinarRegistrationId) {
        return del("/admin/training/enrollment/webinar/registration", { webinarRegistrationId });
    },

    //Delete a course Question
    deleteCourseExamQuestion(courseQuestionId) {
        return del("/admin/training/course/exam/question", { courseQuestionId });
    },

    //Get a course question
    getCourseExamQuestion(params) {
        return get("/admin/training/course/exam/question", params);
    },

    //Create a course question
    createCourseExamQuestion(params) {
        return post("/admin/training/course/exam/question", params);
    },

    //Update a course question
    updateCourseExamQuestion(params) {
        return patch("/admin/training/course/exam/question", params);
    },

    //Search course questions
    searchCourseExamQuestion(params) {
        return get("/admin/training/course/exam/question/search", params);
    },

    //Remove the exam on the course
    deleteCourseExam(courseId) {
        return del("/admin/training/course/exam", { courseId });
    },

    //Add the exam on the course
    createCourseExam(params) {
        return post("/admin/training/course/exam", params);
    },

    //Get an exam attempt
    getEnrollmentExamAttempt(examAttemptId) {
        return get("/admin/training/enrollment/exam/attempt", { examAttemptId });
    },

    //Get an exam attempt answer
    getEnrollmentExamAttemptAnswer(ExamAttemptAnswerId) {
        return get("/admin/training/enrollment/exam/attempt/answer", { ExamAttemptAnswerId });
    },

    //Search exam attempt answers
    searchEnrollmentExamAttemptAnswer(params) {
        return get("/admin/training/enrollment/exam/attempt/answer/search", params);
    },

    //Search exam attempts
    searchEnrollmentExamAttempt(params) {
        return get("/admin/training/enrollment/exam/attempt/search", params);
    },
};

export { TrainingService };
