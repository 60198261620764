const state = {
    files: [],
};

const getters = {
    getItemByKey: (state) => (key) => {
        return state.files.find((item) => item.key === key);
    },
};

const mutations = {
    setFileList(state, files) {
        state.files = files;
    },

    addFile(state, blob) {
        state.files.push(blob);
    },

    removeFile(state, key) {
        state.files = state.files.filter(function (obj) {
            return obj.key !== key;
        });
    },
    removeFiles(state) {
        if (state.files.length) {
            state.files = [];
        }
    },
};

const actions = {
    retrieveFileList({ commit }) {
        commit("setFileList", state.files);
    },

    addFile({ commit, getters, state }, asset) {
        let blob = asset.value;
        if (getters.getItemByKey(asset.key)) {
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
            try {
                // localstorage quota is ~ 10MB... prefer to cache a bunch of small files - todo: use indexedDB.
                if (blob.size < 50000 && asset.key) {
                    commit("addFile", { key: asset.key, value: reader.result });
                }
            } catch (e) {
                if (e.message.includes("quota")) {
                    commit("removeFiles");
                }
            }
        };
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
