import { get, post, patch, del } from "./api.service";

const TimeClockService = {
    getTimeClock(id) {
        return get("/admin/timeclock", { id });
    },

    getTimeClocks(params) {
        return get("/admin/timeclock/search", params);
    },

    searchTimeclockCoordinates(params) {
        return get("/admin/timeclock/coordinates/search", params);
    },

    reviewTimeClock(params) {
        return post("/admin/timeclock/review", params);
    },

    createTimeClock(params) {
        return post("/admin/timeclock", params);
    },

    updateTimeClock(params) {
        return patch("/admin/timeclock", params);
    },

    deleteTimeClock(id) {
        return del("/admin/timeclock", { id });
    },

    clockIn(params) {
        return post("/admin/timeclock/clockin", params);
    },

    clockOut(params) {
        return post("/admin/timeclock/clockout", params);
    },

    findTimeClockNotes(params) {
        return get("/admin/timeclock/note/search", params);
    },

    getTimeClockNote(id) {
        return get("/admin/timeclock/note", { id });
    },

    addTimeClockNote(params) {
        return post("/admin/timeclock/note", params);
    },

    updateTimeClockNote(params) {
        return patch("/admin/timeclock/note", params);
    },

    deleteTimeClockNote(id) {
        return del("/admin/timeclock/note", { id });
    },

    findTimeClockCategories(params) {
        return get("/admin/timeclock/category/search", params);
    },

    getTimeClockCategory(id) {
        return get("/user/timeclock/category", { id });
    },

    createTimeClockCategory(params) {
        return post("/admin/timeclock/category", params);
    },

    updateTimeClockCategory(params) {
        return patch("/admin/timeclock/category", params);
    },

    activateTimeClockCategory(id) {
        return post("/admin/timeclock/category/activate", { id });
    },

    deactivateTimeClockCategory(id) {
        return post("/admin/timeclock/category/deactivate", { id });
    },
};

export { TimeClockService };
