import { get, post, patch, del, setAuthHeader } from "./api.service";
import { TokenService } from "./token.service";
import store from "@/store";

const UserService = {
    refreshToken() {
        return fetch(new URL("/public/auth/refresh-token", store.state.api.apiURL), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-API-KEY": store.state.api.apiKey,
            },
            body: JSON.stringify({ token: TokenService.getRefreshToken() }),
        })
            .then((r) => {
                if (!r.ok) store.dispatch("user/logout");
                return r.json().then((json) => {
                    const accessToken = json.data.accessToken.token
                        ? json.data.accessToken.token
                        : json.data.accessToken;
                    TokenService.saveToken(accessToken);
                    const refreshToken = json.data.refreshToken.token
                        ? json.data.refreshToken.token
                        : json.data.refreshToken;
                    TokenService.saveRefreshToken(refreshToken);
                    setAuthHeader();
                    return Promise.resolve();
                });
            })
            .catch(() => store.dispatch("user/logout"));
    },

    forgotPassword(params) {
        return post("/public/auth/forgot-password", params);
    },

    resetPassword(params) {
        return post("/public/auth/reset-password", params);
    },

    confirmEmail(params) {
        return post("/public/auth/confirm-email", params);
    },

    changePassword(params) {
        return post("/user/auth/change-password", params);
    },

    getUsers(params) {
        return get("/admin/user/search", params);
    },

    getUser(id) {
        return get("/admin/user", { id });
    },

    updateUser(params) {
        return patch("/admin/user", params);
    },

    setRole(params) {
        return post("/admin/user/role", params);
    },

    deleteUser(id) {
        return del("/admin/user", { id });
    },

    suspendUser(id) {
        return post("/admin/user/disable", { id });
    },

    activateUser(id) {
        return post("/admin/user/enable", { id });
    },

    getCurrentUser() {
        return get("/user/user/me");
    },

    updateCurrentUser(params) {
        return patch("/user/user/me", params);
    },

    getBillingInfo() {
        return get("/admin/user/billable/count");
    },

    verifyCaptcha(token) {
        const data = {
            recaptchaResponse: token,
            anonymousKey: store.state.api.apiKey,
        };
        return post("/public/auth/verify-captcha", data);
    },

    // getProfileImageDownloadUrl(userId) {
    //     return post("/admin/user/profileimage/download", { userId });
    // },

    // getProfileImageUploadUrl(userId) {
    //     return post("/admin/user/profileimage/upload", { userId });
    // },

    linkProfileImage(params) {
        return post("/user/user/profileimage/link", params);
    },

    unlinkProfileImage() {
        return post("/user/user/profileimage/unlink");
    },
};

export { UserService };
