import Vuetify from "@plugins/vuetify";
import { UserService } from "@services";
import { tracer } from "@/diagnostics/tracer";

const state = {
    lang: "en",
    darkTheme: true, // dark mode by default
    timeFormat24Hour: false,
    timeFormatShowSeconds: true,
    location: "local",
    version: "local",
    showDevFAB: false,
    preferGridsOverLists: false,
};

const getters = {
    settings: (state) => {
        return { ...state };
    },
};

const actions = {
    async updateUserProfile({ dispatch }, params) {
        const r = await UserService.updateCurrentUser(params);
        dispatch("user/setUser", null, { root: true });
        return r;
    },
};

const mutations = {
    toggleDarkMode(state) {
        state.darkTheme = !state.darkTheme;
        Vuetify.framework.theme.dark = !Vuetify.framework.theme.dark;
        tracer.info("[VUE] Toggle Dark Mode", state.darkTheme);
    },

    toggleTimeFormat(state) {
        state.timeFormat24Hour = !state.timeFormat24Hour;
        tracer.info("[VUE] Toggle Time Format 24 Hour", state.timeFormat24Hour);
    },

    toggleTimeFormatShowSeconds(state) {
        state.timeFormatShowSeconds = !state.timeFormatShowSeconds;
        tracer.info("[VUE] Toggle Time Format Show Seconds", state.timeFormatShowSeconds);
    },

    setTimeZone(state, location) {
        state.location = location;
        tracer.info("[VUE] Set Time Zone", state.location);
    },

    setTimeFormat24Hour(state, timeFormat24Hour) {
        state.timeFormat24Hour = timeFormat24Hour;
        tracer.info("[VUE] Set Time Format 24 Hour", state.timeFormat24Hour);
    },

    setTimeFormatShowSeconds(state, timeFormatShowSeconds) {
        state.timeFormatShowSeconds = timeFormatShowSeconds;
        tracer.info("[VUE] Set Time Format Show Seconds", state.timeFormatShowSeconds);
    },

    setLang(state, lang) {
        state.lang = lang;
        tracer.info("[VUE] Set Language", state.lang);
    },

    setShowDevFAB(state, show) {
        state.showDevFAB = show;
    },

    setPreferGridsOverLists(state, prefer) {
        state.preferGridsOverLists = prefer;
    },

    version(state, version) {
        state.version = version;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
