import { Loader } from "@googlemaps/js-api-loader";

export default {
    install(Vue, store) {
        let googleMapsLoader = new Loader({
            apiKey: store.state.api.googleMapsApiKey,
            version: "weekly",
            region: "US",
            language: store.state.settings.lang,
        });

        googleMapsLoader
            .load()
            .then((google) => {
                Vue.googleMaps = google.maps;
                Vue.prototype.$googleMaps = google.maps;
            })
            .catch((e) => {
                /* eslint-disable-next-line */
                console.error(e);
            });
    },
};
