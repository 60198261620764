import { get, post, patch, put, del } from "./api.service";

export const IntegrationService = {
    //support for admin and operator - better to detect permission on api via token... api does not support this.
    deleteIntegrationUser(id, isOperator = false) {
        return del(`/${isOperator ? "operator" : "admin"}/integration/user`, { id });
    },

    getIntegrationUser(id, isOperator = false) {
        return get(`/${isOperator ? "operator" : "admin"}/integration/user`, { id });
    },

    //no api
    patchIntegrationUser(params, isOperator = false) {
        return patch(`/${isOperator ? "operator" : "admin"}/integration/user`, params);
    },

    createIntegrationUser(params, isOperator = false) {
        return post(`/${isOperator ? "operator" : "admin"}/integration/user`, params);
    },

    searchIntegrationUser(params, isOperator = false) {
        return get(`/${isOperator ? "operator" : "admin"}/integration/user/search`, params);
    },

    //no api
    getIntegrationCompany(id, isOperator = false) {
        return get(`/${isOperator ? "operator" : "admin"}/integration/company`, { id });
    },

    //operator only
    deleteIntegrationCompany(id) {
        return del("/operator/integration/company", { id });
    },

    patchIntegrationCompany(params) {
        return patch("/operator/integration/company", params);
    },

    createIntegrationCompany(params) {
        return post("/operator/integration/company", params);
    },

    updateIntegrationCompany(params) {
        return put("/operator/integration/company", params);
    },

    searchIntegrationCompany(params) {
        return get("/operator/integration/company/search", params);
    },
};
