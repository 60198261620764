<template>
    <v-app>
        <core-view-shared />
    </v-app>
</template>

<script>
import { ComponentLoader } from "@plugins/componentLoader";

export default {
    name: "Shared",

    metaInfo: {
        title: "Home",
    },

    components: ComponentLoader({
        CoreViewShared: "core/ViewShared.vue",
    }),

    data: () => ({
        drawer: false,
        expandOnHover: false,
    }),

    computed: {
        showFadeInBackground() {
            if (!this.$route) {
                return true;
            }

            if (this.$route.meta?.noBackground) {
                return false;
            }

            if (this.$route.meta?.public) {
                return true;
            }

            return false;
        },

        showAppBarAndDrawer() {
            if (!this.$route) {
                return false;
            }

            if (this.$route.meta?.public) {
                return false;
            }

            return true;
        },
    },

    created() {
        if (this.$vuetify.breakpoint.mdAndUp) {
            this.drawer = true;
        }
        console.log("shared");
        this.$vuetify.theme.dark = false;
    },
    beforeDestroy() {
        console.log("beforeDestroy");
    },
};
</script>
