import { get, post, patch, del } from "./api.service";

const CompanyService = {
    findCompanies(params) {
        return get("/user/company/search", params);
    },

    getCompany(id) {
        return get("/user/company", { id });
    },

    findCompanyAddresses(params) {
        return get("/user/company/address/search", params);
    },

    getCompanyAddress(id) {
        return get("/user/company/address", { id });
    },
    updateCompany(params) {
        return patch("/admin/company", params);
    },

    /**
     * company group
     */
    findCompanyGroups(params) {
        return get("/user/company/group/search", params);
    },
    getCompanyGroup(id) {
        return get("/user/company/group", { id });
    },

    createCompanyGroup(params) {
        return post("/admin/company/group", params);
    },

    removeCompanyGroup(id) {
        return del("/admin/company/group", { id });
    },

    updateCompanyGroup(params) {
        return patch("/admin/company/group", params);
    },

    /**
     * company group users
     */
    addCompanyGroupUser(params) {
        return post("/admin/company/group/user", params);
    },

    updateCompanyGroupUser(params) {
        return patch("/admin/company/group/user", params);
    },

    removeCompanyGroupUser(id) {
        return del("/admin/company/group/user", { id });
    },

    getCompanyGroupUserList(params) {
        return get("/user/company/group/users/search", params);
    },
};

export { CompanyService };
