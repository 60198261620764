const state = {
    content: "",
    color: "",
    timeout: "",
    type: "",
};

const mutations = {
    showMessage(state, payload) {
        state.content = payload.content;
        state.color = payload.color || "success";
        state.timeout = payload.timeout || 5000;
        state.type = payload.type ? payload.type : "notification";
    },
    hideMessage() {},
};

export default {
    namespaced: true,
    state,
    mutations,
};
