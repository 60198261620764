import { get, post, patch, del } from "./api.service";

export const ACLservice = {
    getACL(params) {
        return get("/admin/acl", params);
    },
    createACL(params) {
        return post("/admin/acl", params);
    },

    updateACL(params) {
        return patch("/admin/acl", params);
    },

    deleteACL(id) {
        return del("/admin/acl", { id });
    },
};
