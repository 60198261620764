import { get, post, patch } from "./api.service";

const CostCodeService = {
    getCostCode(id) {
        return get("/user/cost/code", { id });
    },

    findCostCodes(params) {
        return get("/admin/cost/code/search", params);
    },

    createCostCode(params) {
        return post("/admin/cost/code", params);
    },

    updateCostCode(params) {
        return patch("/admin/cost/code", params);
    },

    activateCostCode(id) {
        return post("/admin/cost/code/activate", { id });
    },

    deactivateCostCode(id) {
        return post("/admin/cost/code/deactivate", { id });
    },
};

export { CostCodeService };
