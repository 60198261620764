import { get, post, del } from "./api.service";

const MessageService = {
    getUnreadMessages(params) {
        return get("/user/notification/headers/unread", params);
    },

    getMessages(params) {
        return get("/user/notification/headers/search", params);
    },

    readMessage(notificationTrackingId) {
        return post("/user/notification/read", { notificationTrackingId });
    },

    deleteSendMessage(id) {
        return del("/admin/notification/recipient", { id });
    },

    getSentMessages(params) {
        return get("/admin/notification/search", params);
    },

    getSentMessage(id) {
        return get("/admin/notification", { id });
    },
    getMessageRecipients(params) {
        return get("/admin/notification/recipient/search", params);
    },

    getMessageRecipient(id) {
        return get("/admin/notification/recipient", { id });
    },

    sendMessage(params) {
        return post("/admin/notification", params);
    },

    archiveSentMessage(id) {
        return post("/admin/notification/archive", { id });
    },

    archiveInboxMessage(id) {
        return post("/user/notification/archive", { id });
    },

    restoreSentMessage(id) {
        return post("/admin/notification/restore", { id });
    },

    restoreInboxMessage(id) {
        return post("/user/notification/restore", { id });
    },

    getDraftMessage(id) {
        return get("/admin/notification/draft", { id });
    },

    getDraftMessages(params) {
        return get("/admin/notification/draft/search", params);
    },

    createDraftMessage(params) {
        return post("/admin/notification/draft", params);
    },

    deleteDraftMessage(id) {
        return del("/admin/notification/draft", { id });
    },
};

export { MessageService };
