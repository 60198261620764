import { get, post } from "./api.service";

const PublicService = {
    //Forms
    getRevision(id) {
        return get("/public/form/revision", { id });
    },

    getFormSubmission(id) {
        return get("/public/form/submission", { id }, true);
    },

    getFormSubmissionField(id) {
        return get("/public/form/submission/field", { id });
    },

    findFormSubmissionFields(params) {
        return get("/public/form/submission/field/search", params);
    },

    getFormAsset(params) {
        return get("/public/form/asset", params);
    },

    getFormSubmissionAsset(params) {
        return get("/public/form/submission/asset", params);
    },

    downloadAsset(params) {
        return post("/public/asset/download", params, true);
    },
};

export { PublicService };
