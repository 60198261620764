import { get, post, patch, del } from "./api.service";

const FormService = {
    findForms(params) {
        return get("/admin/form/search", params);
    },

    getForm(id) {
        return get("/admin/form", { id });
    },

    createForm(params) {
        return post("/admin/form", params);
    },

    updateForm(params) {
        return patch("/admin/form", params);
    },

    deleteForm(id) {
        return del("/admin/form", { id });
    },

    /**
     * Form revisions
     */
    createNewRevisionDraft(params) {
        // It's critical to increment the schemaVersion for every production release only if the form schema json has changed!
        return post("/admin/form/revision/draft", { ...params, schemaVersion: 1 });
    },

    updateFormRevisionDraft(params) {
        return patch("/admin/form/revision/draft", params);
    },

    deleteFormRevisionDraft(id) {
        return del("/admin/form/revision/draft", { id });
    },

    findFormRevisions(params) {
        return get("/admin/form/revision/search", params);
    },

    getRevision(id) {
        return get("/admin/form/revision", { id });
    },

    publishRevision(id) {
        return post("/admin/form/revision", { id });
    },

    deleteFormRevision(id) {
        return del("/admin/form/revision", { id });
    },

    /**
     * Form submissions
     */
    findFormSubmissions(params) {
        return get("/admin/form/submission/search", params);
    },

    getFormSubmission(id) {
        return get("/admin/form/submission", { id });
    },

    deleteFormSubmission(id) {
        return del("/admin/form/submission", { id });
    },

    reviewSubmission(params) {
        return post("/admin/form/submission/review", params);
    },

    findFormSubmissionFields(params) {
        return get("/admin/form/submission/field/search", params);
    },

    getFormSubmissionField(id) {
        return get("/admin/form/submission/field", { id });
    },

    /**
     * Form assignments
     */
    findFormAssignments(params) {
        return get("/admin/form/assignment/search", params);
    },

    updateFormAssignment(params) {
        return patch("/admin/form/assignment", params);
    },

    deleteFormAssignment(id) {
        return del("/admin/form/assignment", { id });
    },

    assignFormToUsers(params) {
        return post("/admin/form/assignment", params);
    },

    /**
     * Form header
     */
    deleteFormAsset(params) {
        return del("/admin/form/asset", { params });
    },

    getFormAsset(params) {
        return get("/admin/form/asset", params);
    },

    findFormAsset(params) {
        return get("/admin/form/asset/search", params);
    },

    createFormAsset(params) {
        return post("/admin/form/asset", params);
    },

    getFormSubmissionAsset(params) {
        return get("/admin/form/submission/asset", params);
    },

    findFormSubmissionAsset(params) {
        return get("/admin/form/submission/asset/search", params);
    },

    /**
     * Form Stop Checks
     */
    deleteFormStopCheck(id) {
        return del("/admin/form/revision/draft/stopcheck", { id });
    },

    updateFormStopCheck(params) {
        return patch("/admin/form/revision/draft/stopcheck", params);
    },

    createFormStopCheck(params) {
        return post("/admin/form/revision/draft/stopcheck", params);
    },

    deleteFormStopCheckReviewer(id) {
        return del("/admin/form/revision/draft/stopcheck/reviewer", { id });
    },

    createFormStopCheckReviewer(params) {
        return post("/admin/form/revision/draft/stopcheck/reviewer", params);
    },

    getFormStopCheck(id) {
        return get("/admin/form/revision/stopcheck", id);
    },

    getFormStopCheckReviewer(id) {
        return get("/admin/form/revision/stopcheck/reviewer", id);
    },

    findFormStopCheckReviewers(params) {
        return get("/admin/form/revision/stopcheck/reviewer/search", params);
    },

    findFormStopChecks(params) {
        return get("/admin/form/revision/stopcheck/search", params);
    },

    addFormSubmissionNotifications(params) {
        return post("/admin/form/notification", params);
    },

    getFormSubmissionNotifications(id) {
        return get("/admin/form/notification", id);
    },

    findFormSubmissionNotifications(params) {
        return get("/admin/form/notification/search", params);
    },

    deleteFormSubmissionNotifications(id) {
        return del("/admin/form/notification", id);
    },
};

export { FormService };
