import { get, post, patch } from "./api.service";

const PaperOSService = {
    listDocuments(params, isOperator = false) {
        return get(`/${isOperator ? "operator" : "admin"}/paper-os/documents`, params);
    },

    getOrganization(params) {
        return get("/operator/paper-os/org", params);
    },

    getOrganizations(params) {
        return get("/operator/paper-os/orgs", params);
    },

    getRecord(params, isOperator = false) {
        return get(`/${isOperator ? "operator" : "admin"}/paper-os/record`, params);
    },

    patchRecord(params, isOperator = false) {
        return patch(`/${isOperator ? "operator" : "admin"}/paper-os/record`, params);
    },

    createRecord(params, isOperator) {
        return post(`/${isOperator ? "operator" : "admin"}/paper-os/record`, params);
    },

    listRecords(params, isOperator = false) {
        return get(`/${isOperator ? "operator" : "admin"}/paper-os/records`, params);
    },

    listWorkflows(params) {
        return get("/operator/paper-os/workflows", params);
    },

    createOrg(params) {
        return post("/operator/paper-os/org", params);
    },
};

export { PaperOSService };
