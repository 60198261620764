import Vue from "vue";

import { TokenService } from "./token.service";
import { UserService } from "./user.service";
import i18n from "@/i18n";
import store from "@/store";
import router from "@/router";
import { tracer } from "@/diagnostics/tracer.js";

let isTokenRefreshing = false;
let retry = [];

let base;
const headers = new Headers();

export const init = () => {
    base = store.state.api.apiURL;
    headers.set("X-API-KEY", store.state.api.apiKey);
    headers.set("Content-Type", "application/json");
    if (TokenService.getToken()) {
        setAuthHeader();
    }
};

export const setAuthHeader = () => headers.set("Authorization", `Bearer ${TokenService.getToken()}`);

export const removeAuthHeader = () => headers.delete("Authorization");

export const get = (urlString, params, silent = false) => request(urlString, params, "GET", silent);

export const post = (urlString, data, silent = false) => request(urlString, data, "POST", silent);

export const patch = (urlString, data) => request(urlString, data, "PATCH");

export const put = (urlString, data) => request(urlString, data, "PUT");

export const del = (urlString, data) => request(urlString, data, "DELETE");

const request = (urlString, params, method, silent) => {
    // put all requests in pending Promise until token is refreshed
    if (isTokenRefreshing) {
        tracer.info("Queued API Call until Token Refresh", {
            urlString: urlString,
            params: tracer.sanitize(params),
            method: method,
        });

        return new Promise((resolve) => {
            retry.push(() => resolve(request(urlString, params, method, silent)));
        });
    }

    const url = new URL(urlString, base);
    const options = { method, headers };
    if (method === "GET") {
        url.search = new URLSearchParams(params);
    } else {
        options.body = JSON.stringify(params);
    }

    return fetch(url, options)
        .then((r) => {
            // parse and wrap the response in a new simplified Promise
            return new Promise((resolve, reject) =>
                r.json().then((json) => {
                    if (r.ok) {
                        tracer.info("[API] OK | " + url, {
                            params: tracer.sanitize(params),
                            method: method,
                            response: json,
                        });

                        return resolve({ data: json.data, meta: json.meta });
                    }

                    if (r.status === 401 && urlString === "/public/auth/confirm-email") {
                        tracer.error("[API] Bad Email Confirmation - Redirecting to Login | " + url, {
                            params: tracer.sanitize(params),
                            method: method,
                            response: json,
                        });

                        router.push("/login", { replace: true });
                    } else if (r.status === 401 && urlString !== "/public/auth/authenticate") {
                        tracer.error("[API] Received 401, starting token refresh handler | " + url, {
                            params: tracer.sanitize(params),
                            method: method,
                            response: json,
                        });

                        if (!isTokenRefreshing) {
                            isTokenRefreshing = true;

                            UserService.refreshToken().then(() => {
                                isTokenRefreshing = false;
                                // execute all pending requests and reset queue
                                retry.forEach((cb) => cb());
                                retry = [];
                            });
                        }

                        // put all requests in pending Promise until token is refreshed
                        retry.push(() => resolve(request(urlString, params, method)));
                    } else {
                        tracer.error("[API] Error | " + url, {
                            params: tracer.sanitize(params),
                            method: method,
                            response: json,
                        });

                        const code = json.errors[0].englishTranslation; // TODO: replace with API err code

                        if (!silent) {
                            Vue.prototype.$snackbar.showMessage({
                                content: i18n.t(code),
                                color: "error",
                                timeout: 5000,
                            });
                        }

                        reject({ status: r.status, code: json.errors[0].code });
                    }
                }),
            );
        })
        .catch((error) => {
            if (Object.keys(error).length === 0) {
                tracer.error("[API] Unreachable | " + url, {
                    params: tracer.sanitize(params),
                    method: method,
                });

                Vue.prototype.$snackbar.showMessage({
                    content: "A network error has occured!",
                    color: "error",
                    timeout: -1,
                }); // TODO: Localize
            } else {
                throw error;
            }
        });
};
