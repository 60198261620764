import { get, post, patch, del } from "./api.service";

const JobService = {
    getJob(id) {
        return get("/admin/job", { id });
    },

    findJobs(params) {
        return get("/admin/job/search", params);
    },

    createJob(params) {
        return post("/admin/job", params);
    },

    updateJob(params) {
        return patch("/admin/job", params);
    },

    assign(type, params) {
        return post(`/admin/job/${type}`, params);
    },

    removeGroupAssignment(id) {
        return del("/admin/job/group", { id });
    },

    removeUserAssignment(id) {
        return del("/admin/job/user", { id });
    },

    removeCodeAssignment(type, params) {
        return del(`/admin/job/${type}`, params);
    },

    updateUserAssignment(params) {
        return patch("/admin/job/user", params);
    },
    findJobCodes(params) {
        return get("/admin/job/code/search", params);
    },

    findJobAssignments(type) {
        return (params) => get(`/admin/job/${type}/search`, params);
    },

    searchJobAssignments(params) {
        return get("/admin/job/user/search", params);
    },

    searchGroupJobAssignments(params) {
        return get("/admin/job/group/search", params);
    },

    activateJob(id) {
        return post("/admin/job/restore", { id });
    },

    deactivateJob(id) {
        return post("/admin/job/archive", { id });
    },

    searchJobCostCodes(params) {
        return get("/admin/job/code/search", params);
    },

    checkUserCanAccessJob(params) {
        return get("/admin/job/access", params);
    },
};

export { JobService };
