import { get, post, patch, del } from "./api.service";

export const DocumentService = {
    searchDocuments(params) {
        return get("/admin/document/search", params);
    },

    getDocument(documentId) {
        return get("/admin/document", { documentId });
    },

    createDocument(params) {
        return post("/admin/document", params);
    },

    updateDocument(params) {
        return patch("/admin/document", params);
    },

    deleteDocument(params) {
        return del("/admin/document", params);
    },

    searchDocAssets(params) {
        return get("/admin/document/asset/search", params);
    },

    getDocAsset(params) {
        return get("/admin/document/asset", params);
    },

    downloadDocAsset(params) {
        return post("/admin/document/asset/download", params, true);
    },

    updateDocAsset(params) {
        return patch("/admin/document/asset", params);
    },

    deleteDocAsset(documentAssetId) {
        return del("/admin/document/asset", { documentAssetId });
    },

    uploadDocAssetStart(params) {
        return post("/admin/document/asset/upload/begin", params);
    },

    uploadDocAssetComplete(params) {
        return post("/admin/document/asset/upload/complete", params);
    },

    assignDocAssetOwner(params) {
        return post("/admin/document/ownership/give", params);
    },

    takeDocOwnership(params) {
        return post("/admin/document/ownership/take", params);
    },

    getDocumentOwner(params) {
        return get("/admin/user", params);
    },
};
