import { get, post } from "./api.service";

const InternalService = {
    versionGet: () => get("/public/util/version"),

    serverTimeGet: () => get("/public/util/servertime"),

    adminHeartbeat: () => get("/admin/util/heartbeat"),

    updates(params) {
        return get("/admin/util/updates", params);
    },

    getUpdatesPreference() {
        return get("/admin/util/updates/suppress");
    },

    setUpdatesPreference(params) {
        return post("/admin/util/updates/suppress", params);
    },

    acknowledgeUpdates() {
        return post("/admin/util/updates/acknowledge");
    },
};

export { InternalService };
