<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TokenService } from "@services";
import { tracer } from "@/diagnostics/tracer.js";

tracer.init();

export default {
    name: "App",

    computed: {
        ...mapGetters("settings", ["settings"]),
        layout() {
            return this.$route.meta.layout || "default-layout";
        },
    },

    metaInfo: {
        //TODO: title: this.$router.currentRoute,
        //TODO: explore useful meta info
        titleTemplate: "%s | Ranes",
    },

    methods: {
        ...mapActions("user", ["setUser", "setUserCreds"]),
        ...mapActions("heartbeat", ["checkHeartbeat"]),
    },

    mounted() {
        if (this.layout == "share-layout") {
            this.$vuetify.theme.dark = false;
        } else {
            if (this.settings.darkTheme != null) {
                this.$vuetify.theme.dark = this.settings.darkTheme;
            } else {
                this.$vuetify.theme.dark = true;
            }
        }

        if (TokenService.getToken()) {
            this.setUser();
            this.setUserCreds();
            this.checkHeartbeat();
        }
    },
};
</script>
