import Vue from "vue";
import VueMeta from "vue-meta";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import filters from "@plugins/filters";
import vuetify from "@plugins/vuetify";
import GoogleMaps from "@plugins/googleMaps";
import snackBarPlugin from "@plugins/snackbar";
import { init } from "@services";
import VueClipboard from "vue-clipboard2";

Vue.config.productionTip = false;

Vue.use(snackBarPlugin, { store });
Vue.use(filters);
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
});

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
    beforeCreate() {
        const envVars = {
            VUE_APP_API_URL: config.VUE_APP_API_URL || process.env.VUE_APP_API_URL,
            VUE_APP_API_ANONYMOUSKEY: config.VUE_APP_API_ANONYMOUSKEY || process.env.VUE_APP_API_ANONYMOUSKEY,
            VUE_APP_APP_URL: config.VUE_APP_APP_URL || process.env.VUE_APP_APP_URL,
            VUE_APP_GOOGLE_MAPS_API_KEY: config.VUE_APP_GOOGLE_MAPS_API_KEY || process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        };

        Object.entries(envVars).forEach(([key, value]) => {
            if (!value || value === "") {
                /* eslint-disable no-console */
                console.error(`${key} is missing`);
                /* eslint-enable no-console */
            }
        });

        store.commit("api/apiURL", envVars.VUE_APP_API_URL);
        store.commit("api/apiKey", envVars.VUE_APP_API_ANONYMOUSKEY);
        store.commit("api/userAppUrl", envVars.VUE_APP_APP_URL);
        store.commit("api/googleMapsApiKey", envVars.VUE_APP_GOOGLE_MAPS_API_KEY);

        // baked by build system
        store.commit("settings/version", process.env.VUE_APP_VERSION);

        init();
    },
    created() {
        Vue.use(GoogleMaps, this.$store);
    },
}).$mount("#app");
