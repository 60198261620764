import { v4 as uuid } from "uuid";

export const TRACE_LOG_KEY = "trace_log";
export const TRACE_MAX_SIZE = 2000000; // limit to 2 MB

const SANITIZE_KEYWORDS = ["password"];
const SANITIZE_VALUE = "***SANITIZED***";

export const LEVEL_INFO = "info";
export const LEVEL_ERROR = "error";

export const tracer = {
    init() {
        tracer.info("[VUE] App Init");
    },

    info(message, data = null) {
        this.log(message, LEVEL_INFO, data);
    },

    error(message, data = null) {
        this.log(message, LEVEL_ERROR, data);
    },

    log(message, level, data = null) {
        var logEntries = null;
        var logEntriesString = null;
        var logEntriesSize = 0;

        try {
            logEntriesString = localStorage.getItem(TRACE_LOG_KEY);
            logEntriesSize = logEntriesString.length * 2;
            logEntries = JSON.parse(logEntriesString);
        } catch (e) {
            // eslint-disable-next-line
        }

        if (logEntries == null) {
            logEntries = [];
            logEntriesString = JSON.stringify(logEntries);
            logEntriesSize = logEntriesString.length * 2;
        }

        var entry = {
            message: message,
            data: data,
            level: level,
            timestamp: new Date().toISOString(),
            uuid: uuid(),
        };

        logEntries.push(entry);

        logEntriesString = JSON.stringify(logEntries);
        logEntriesSize = logEntriesString.length * 2;

        while (logEntriesSize > TRACE_MAX_SIZE) {
            logEntries.shift();

            logEntriesString = JSON.stringify(logEntries);
            logEntriesSize = logEntriesString.length * 2;
        }

        localStorage.setItem(TRACE_LOG_KEY, logEntriesString);
    },

    sanitize(params) {
        if (!params) {
            return params;
        }

        Object.keys(params)
            .filter((keys) => SANITIZE_KEYWORDS.includes(keys.toLowerCase()))
            .forEach((badKey) => (params[badKey] = SANITIZE_VALUE));

        return params;
    },

    getSessionTrace() {
        return localStorage.getItem(TRACE_LOG_KEY);
    },

    clearSessionTrace() {
        localStorage.setItem(TRACE_LOG_KEY, "");
        this.info("[TRACE] Session Trace Cleared");
    },
};
export default tracer;
