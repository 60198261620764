<template>
    <v-app>
        <DevFAB />

        <fade-in-background v-if="showFadeInBackground" />

        <dashboard-core-app-bar v-if="showAppBarAndDrawer" :drawer.sync="drawer" />

        <dashboard-core-drawer v-if="showAppBarAndDrawer" :drawer.sync="drawer" />

        <dashboard-core-view />

        <dashboard-core-snack-bar />
    </v-app>
</template>

<script>
import { ComponentLoader } from "@plugins/componentLoader";

export default {
    name: "DashboardIndex",

    metaInfo: {
        title: "Home",
    },

    components: ComponentLoader({
        FadeInBackground: "core/FadeInBackground.vue",
        DashboardCoreAppBar: "core/AppBar.vue",
        DashboardCoreDrawer: "core/Drawer.vue",
        DashboardCoreView: "core/View.vue",
        DashboardCoreSnackBar: "core/AppSnackBar.vue",
        DevFAB: "DevFAB.vue",
    }),

    data: () => ({
        drawer: false,
        expandOnHover: false,
    }),

    computed: {
        showFadeInBackground() {
            if (!this.$route) {
                return true;
            }

            if (this.$route.meta?.noBackground) {
                return false;
            }

            if (this.$route.meta?.public) {
                return true;
            }

            return false;
        },

        showAppBarAndDrawer() {
            if (!this.$route) {
                return false;
            }

            if (this.$route.meta?.public) {
                return false;
            }

            return true;
        },
    },

    created() {
        if (this.$vuetify.breakpoint.mdAndUp) {
            this.drawer = true;
        }
    },
};
</script>
