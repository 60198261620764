export default {
    install: (Vue, { store }) => {
        if (!store) {
            throw new Error("Please provide vuex store.");
        }

        Vue.prototype.$snackbar = {
            showMessage: function ({ content = "", color = "", timeout = "", type = "notification" }) {
                store.commit("snackbar/showMessage", { content, color, timeout, type });
            },
            hideMessage: function () {
                store.commit("snackbar/hideMessage");
            },
        };
    },
};
